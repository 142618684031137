<template>
  <div class="challenge_container" ref="pageScroll">
    <div>
      <img
        :src="require('@/assets/images/challenge/cover.png')"
        class="challenge_cover"
        alt=""
        @load="initScroll"
      />
      <div class="challenge_content">
        <div class="challenge_button_group">
          <div class="challenge_button" @click="goToAdminPage()">
            <img
              :src="require('@/assets/images/challenge/admin.png')"
              class="challenge_identity"
              alt=""
            />
            <span>专家登录</span>
          </div>
        </div>
        <div class="challenge_vote_block">
          <img
            :src="require('@/assets/images/challenge/vote1.png')"
            class="challenge_vote"
            alt=""
            @click="goToVote"
          />
          <img
            :src="require('@/assets/images/challenge/vote2.png')"
            class="challenge_vote"
            alt=""
            @click="goToProficientVote"
          />
        </div>
      </div>
    </div>
    <popup
      v-if="showPopup"
      @doClose="closePopup"
      @doSure="doSure"
      :cfg="popupConfig"
    >
      <div class="literatureSelectionArea_block_popup">
        只有专家才可点击按钮，否则专家本人将无法登录
      </div>
    </popup>
    <popup
      v-if="showExpertVotingPopup"
      :cfg="showExpertVotingPopupConfig"
      @doClose="closeExpertVotingPopup"
      @doSure="doExpertVotingSure"
    >
      <div class="literatureSelectionArea_block_popup">
        您的一票等于大众10票，请慎重评选
      </div>
    </popup>
  </div>
</template>
<script>
import config from "@/common/config";
import tool from "@/common/tool";
import BSScroll from "better-scroll";
import popup from "@/components/unit/popup";
// import wx from "@/common/share";
export default {
  name: "challenge",
  data() {
    return {
      scroll: null,
      showExpertVotingPopup: false,
      showExpertVotingPopupConfig: {
        title: "",
        button: {
          cancel: "取消",
          sure: "确认",
        },
      },

      showPopup: false,
      infoData: {},
    };
  },
  components: {
    popup,
  },
  created() {
    this.infoData = this.$store.state.common.infoData || {}; //百研小安医生信息
  },
  mounted() {
    document.getElementsByTagName("title")[0].innerHTML = "肺癌全国挑战赛专区";
    this.initScroll();
  },
  methods: {
    //判断身份 1 专家(百研小安注册用户) 0 大众
    getRole() {
      return this.infoData.name ? 1 : 0;
    },
    //专家投票
    goToProficientVote() {
      // return this.$tips.error({ text: "投票尚未开始，敬请期待" });
      if (!this.getRole()) {
        return this.$tips.error({
          text: "您不是已注册专家，请点击大众投票专区",
        });
      }
      this.showExpertVotingPopup = true;
    },
    //大众投票
    goToVote() {
      // return this.$tips.error({ text: "投票尚未开始，敬请期待" });
      if (this.getRole()) {
        return this.$tips.error({ text: "您是已注册专家，请点击专家投票专区" });
      }
      //跳转
      this.$router.push({
        path: "/vote",
        query: {
          role: this.getRole(),
        },
      });
    },
    closeExpertVotingPopup() {
      this.showExpertVotingPopup = false;
    },
    doExpertVotingSure() {
      //跳转
      this.$router.push({
        path: "/vote",
        query: {
          role: this.getRole(),
        },
      });
    },
    openPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    doSure() {
      let projectId = config.projectId;
      let companyId = config.companyId;
      let openid = tool.getCookie("openid");
      window.location.href = `https://io.kclass.imedunion.com/#/myMeeting?other_openid=${openid}&other_company_id=${companyId}&projectId=${projectId}`;
    },
    initScroll() {
      setTimeout(() => {
        this.$nextTick(() => {
          if (!this.scroll) {
            this.scroll = new BSScroll(this.$refs.pageScroll, {
              click: true,
              mouseWheel: true,
            });
          } else {
            this.scroll.refresh();
          }
        });
      }, 500);
    },
    goToAdminPage() {
      let projectId = config.projectId;
      // this.$router.push({
      //   path: "/doctorMeets",
      //   query: {
      //     projectId: projectId,
      //   },
      // });
      window.location.href = `https://io.kclass.imedunion.com/#/doctorMeets?projectId=${projectId}`;
    },
    // goToDoctorPage() {
    //   this.showPopup = true;
    // },
  },
};
</script>
<style>
.challenge_container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: rgb(242, 242, 242);
}
.challenge_cover {
  width: 100%;
  height: auto;
}
.challenge_button_group {
  display: flex;
  justify-content: center;
  padding: 12vw 0 4vw;
}
.challenge_button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  width: 100%;
  height: 20vw;
  font-size: 4.8vw;
  background: #9e004c;
  border-radius: 4vw;
  position: relative;
}
.challenge_identity {
  position: absolute;
  left: 50%;
  top: -8vw;
  height: 12vw;
  width: auto;
  transform: translateX(-50%);
  z-index: 1;
}
.challenge_status {
  height: 10vw;
  display: flex;
  justify-content: flex-end;
}
.challenge_status span {
  display: inline-block;
  width: 40vw;
  background: #fff;
  border-top-left-radius: 5vw;
  border-bottom-left-radius: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.8vw;
  font-weight: 600;
  color: #9d1364;
}
.challenge_content {
  padding: 5.6vw 9.6vw;
}
.challenge_vote_block {
  display: flex;
  justify-content: space-between;
}
.challenge_vote {
  width: 48%;
  height: auto;
}
</style>
